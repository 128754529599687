export const UserTypes = {
  AM: "AccountManager",
  AGENCY_ADMIN: "AgencyAdmin",
  IP: "InteractiveProducer",
  AD_ADMIN: "AdvertiserAdmin",
  AD_VIEWER: "AdvertiserViewer",
  SUPER_ADMIN: "SuperAdmin",
  MODERATOR: "Moderator",
  INSIGHTS: "analytics_enabled",
  TOPIC_MONITORING: "topic_monitoring_enabled",
};
export const Home = "Home";
export const AppTypes = {
  CONSOLE: 1,
  RESPONDER: 2,
  AGENCY: 3,
  ADVERTISER: 4,
  ADMIN: 5,
};
export const Filters = {
  ACCOUNT_SETS: "account_sets",
  PLATFORMS: "platforms",
  KEYWORDS: "keywords",
  ADMIN_ADVERTISER_RESPONDERS: "admin_advertiser_responders",
};
export const defaults = {};
export const daysOfWeek = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
];
export const Breakpoints = {
  MOBILE: 500,
  TABLET: {
    PORTRAIT: 979,
    LANDSCAPE: 1279,
  },
};

export const PlanTypes = {
  LITE: "lite",
  MOD: "mod",
};

export default {
  UserTypes,
  AppTypes,
  Filters,
  defaults,
  daysOfWeek,
  Breakpoints,
  PlanTypes,
  Home,
};
